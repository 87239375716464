.react-card-back {
	position: relative !important;
}
.react-card-flip {
	padding-bottom: 20px;
	padding-top: 20px;
	perspective: 1000px;
	transform-style: preserve-3d;
}
.react-card-front {
	margin-top: -50px;
}

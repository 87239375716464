.row {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-box-orient: horizontal;
	-moz-box-orient: horizontal;
	box-orient: horizontal;
	flex-direction: row;

	-webkit-box-pack: center;
	-moz-box-pack: center;
	box-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-moz-box-align: center;
	box-align: center;
	align-items: center;

	background-color: none;
}

.cell {
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	box-flex: 1;
	-webkit-flex: 1 1 auto;
	flex: 1 1 auto;
	max-width: 80%;
	padding: 10px;
	/* margin: 10px;

	background-color: #ffffff; */
	border: none;
	text-align: center;
}
img {
	max-width: 100%;
}

.styles_modal__gNwvD {
	max-width: 1500px;
	position: relative;
	padding: 1.2rem;
	background: transparent;
	background-clip: padding-box;
	box-shadow: 0px 0px 0px 0px rgba(106, 103, 130, 0.25);
	margin: auto;
}

.styles_overlay__CLSq- {
	background: #2dbeff;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 2000;
	padding: 1.2rem;
	opacity: 100%;
}
.styles_closeButton__20ID4 {
	position: absolute;
	top: 14px;
	right: 14px;
	border: none;
	padding: 0;
	background-color: transparent;
	display: none;
}
.row {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-box-orient: horizontal;
	-moz-box-orient: horizontal;
	box-orient: horizontal;
	flex-direction: row;

	-webkit-box-pack: center;
	-moz-box-pack: center;
	box-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-moz-box-align: center;
	box-align: center;
	align-items: center;

	background-color: none;
}

.cell {
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	box-flex: 1;
	-webkit-flex: 1 1 auto;
	flex: 1 1 auto;

	padding: 10px;
	margin: 10px;

	background-color: none;
	border: none;
	text-align: center;
}
img {
	max-width: 100%;
}
/*react loader quotes css to*/
.ghBIEp {
	position: static !important;
}
.hEhxDV {
	width: 99% !important;
	background-color: #f1f1f1 !important;
}
.kqxiXT {
	top: 300px !important;
	background-color: #f1f1f1 !important;
}
